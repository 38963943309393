<template>
  <div class="account">
    <div class="department-name">调查问卷设置</div>
    <div class="form-info" v-loading="state.loadingState">
      <el-form ref="formRef" :model="state.data" :rules="rules" label-width="140px" label-position="right">
        <el-form-item label="投放载体：" prop="wechatAppletFlag" :inline-message="true">
          <el-radio-group v-model="state.data.wechatAppletFlag" :style="inputStyle">
            <el-radio :label="1">小程序</el-radio>
            <el-radio :label="0">公众号</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应用Id：" prop="appId" :inline-message="true">
          <el-input
            v-model="state.data.appId"
            maxlength="36"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入应用Id"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="button-info">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { savePlatformConfig, findByCompanyNo } from '@/apis/sys'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'applet',
  setup() {
    const state = reactive({
      data: {
        appId: '',
        wechatAppletFlag: 1
      }
    })
    const inputStyle = {
      width: '350px',
      'margin-left': '40px'
    }
    const rules = {
      wechatAppletFlag: { required: true, message: '请选择投放载体', trigger: 'change' },
      appId: { required: true, message: '请输入应用Id', trigger: 'blur' }
    }
    const formRef = ref(null)
    const onSubmit = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          try {
            let res = await savePlatformConfig(state.data)
            console.log(res,'res')
            ElMessage.success('操作成功')
          } catch (error) {
            ElMessage.success(error.message)
          }
        }
      })
    }
    const getfindByCompanyNo = async () => {
      let res = await findByCompanyNo({})
      if (res.data) {
        state.data.appId = res.data.appId || ''
        state.data.wechatAppletFlag = res.data.wechatAppletFlag
      }
    }
    onMounted(() => {
      getfindByCompanyNo()
    })
    return {
      state,
      inputStyle,
      rules,
      formRef,
      onSubmit
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
